body {
    margin: 0;
    font-family: -apple-system, BlinkMacSystemFont, 'Pretendard', 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu', 'Cantarell', 'Fira Sans',
        'Droid Sans', 'Helvetica Neue', sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #333;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

.content_box h2 {
    margin-top: 50px;
    padding-top: 20px;
    border-top: 1px dashed #dbdbdb;
}

.content_box li {
    line-height: 1.5;
}

.content_table_box {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
}
.content_detail_table {
    border: 1px solid #efefef;
    border-collapse: collapse;
    text-align: center;
    width: 100%;
    max-width: 1000px;
    margin: 20px 0;
}

.content_detail_table tr {
    /*border-bottom: 1px solid #efefef;*/
}

.content_detail_table td {
    border: 1px solid #efefef;
    /*line-height: 0.8;*/
}

.content_detail_table td:nth-child(1) {
    width: 15%;
}

.content_detail_table td:nth-child(2) {
    width: 35%;
}

.content_detail_table td:nth-child(3) {
    width: 40%;
}

.content_detail_table td:nth-child(4) {
    width: 10%;
}

.content_detail_table li {
    list-style-type: none;
    list-style: none;
}

.content_detail_table_product_name {
    font-size: 1rem;
    font-weight: bold;
}
.content_detail_product_pick {
    border: 2px solid #256fff;
}

.content_detail_product_link {
    color: #256fff;
}

.content_detail_table_product_image img {
    width: 100px;
}

.content_recommend_box {
    /*border: 1px solid #efefef;*/
    padding: 10px;
    /*background-color: #efefef;*/
}

.content_recommend_box_product {
    display: inline-block;
    width: 100%;
    margin-bottom: 20px;
}

.content_recommend_box_imge {
    float: left;
    padding: 20px;
    border: 1px solid #efefef;
    border-radius: 20px;
    margin-right: 50px;
    line-height: 1;
    text-align: center;
    min-width: 400px;
}
@media (max-width: 600px) {
    .content_recommend_box_imge {
        float: none;
        padding: 20px;
        border: 1px solid #efefef;
        border-radius: 20px;
        margin-right: 0 !important;
        line-height: 1;
        text-align: center;
        min-width: auto;
    }
}

.content_recommend_box_imge_mobile {
    float: none;
    padding: 20px;
    border: 1px solid #efefef;
    border-radius: 20px;
    /*margin-right: 50px;*/
    line-height: 1;
    text-align: center;
}

.content_recommend_box_imge img {
    border-radius: 20px;
}
.content_recommend_box_imge_price {
    margin: 5px 0;
    text-align: left;
}
.content_recommend_box li {
    /*line-height: 1.5;*/
    /*list-style-type: none;*/
    padding-bottom: 20px;
}

.content_recommend_box_imge h3 {
    margin: 0;
}

.text_center {
    text-align: center;
}

.text_size_14 {
    font-size: 0.875rem;
}

.text_size_16 {
    font-size: 1rem;
}

.text_bold {
    font-weight: bold;
}

.text_400 {
    font-weight: 400;
}
.color_grey {
    color: #949494;
}

.pick_link {
    color: #256fff;
}

.btn_link {
    background-color: #256fff;
    color: #fff;
    padding: 10px 0;
    border-radius: 7px;
}

.content_num {
    background-color: #000;
    color: white;
    padding: 2px 10px;
    margin-right: 10px;
    border-radius: 5px;
}

.commentbox {
    margin-top: 30px;
    text-align: center;
    background-color: #efefef;
    padding: 10px;
}
