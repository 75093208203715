a {
    text-decoration-line: none;
    color: black;
}

a:link {
    color: black;
}
a:visited {
    color: '#551a8b';
}
a:hover {
    color: black;
}
a:active {
    color: black;
}
